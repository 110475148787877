@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

body{
  font-family: 'system-ui', sans-serif;
  max-width: 100vw !important;
  
}

body, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 9;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: white;
}

body::-webkit-scrollbar-thumb{
  background-color: rgb(18, 18, 18);
}

html {
  scroll-behavior: smooth;
}
@import "../Utils/colors.scss";

.realizations-container {
    .container {
        margin: 0 10%;
        padding: 5rem 0;
        .title-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title {
                margin-bottom: 1rem;
            }
            p {
                color: $lightFontColor;
            }
        }
        .realizations {
          margin-top: 2.5rem;
          display: grid;
          grid-template-columns: auto auto auto auto auto auto;
        }
    }
}

.realizations-large,
.realizations {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
}

@media screen and (max-width: 1600px)  {
  .starter-container {
    .container {
      .realizations-large {
        .large {
          width: 15rem;
          .content {
            width: 15rem
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1820px) {
  .realizations-container {
    .container {
      .realizations {
        margin-top: 2.5rem;
        display: grid;
        grid-template-columns: auto auto auto auto;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .realizations-container {
    .container {
      .realizations {
        margin-top: 2.5rem;
        display: grid;
        grid-template-columns: auto auto auto;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .realizations-container {
    .container {
      .realizations {
        margin-top: 2.5rem;
        display: grid;
        grid-template-columns: auto auto;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .realizations-container {
    .container {
      .realizations {
        margin-top: 2.5rem;
        display: grid;
        grid-template-columns: auto;
      }
    }
  }
}

@media screen and (max-width: 1035px)  {
    .starter-container {
        .container {
          .title-container {
            font-size: 2rem;
          }
          .realizations-large {
            flex-direction: column;
            .large {
              margin: 1rem;
              width: 25rem;
              .content {
                width: 25rem
              }
            }
          }
        }
    }
  }
@import "../Utils/colors.scss";

.footer-container {
    background-color: #333335;
    color: white;
    height: fit-content;
    padding: 1rem 0;
    .main-container {
        margin: 0 10%;
        padding-top: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
        .logo {
            .brand-name {
                display: block;
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0;
                margin-inline-end: 0;
            }
        }

        .google-map {
            font-size: 1.4rem;
            display: flex;
            flex-direction: column;
            
            .map-info {
                font-size: 1rem;
            }
            a {
                img {
                    box-shadow: -9px -8px 11px -8px rgba(255, 255, 255, 1);
                    border-radius: 1rem;
                    width: 24rem;
                    height: 20rem;                
                    transition: 0.4s ease-in-out;
                    &:hover {
                        box-shadow: 9px 8px 11px -8px rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }
}
.copyright {
    margin: 5% 10% 0 10%;
    display: flex;
    justify-content: space-between;
    border-top: #b5b8b8 1px solid;
    .copy {
        color: #b5b8b8;
    }
    .icons {
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .allegro {
          img {
            width: 8rem;
            padding: 0.5rem 0.5rem 0 0.5rem;
          }
          margin-left: 0.3rem;
          transition: 0.5s ease-in-out;
          border-radius: 0.3rem;
          background-color: #2c2c31;
          &:hover {
            background-color: white;
          }
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3.4rem;
          width: 3.4rem;
          background-color: #2c2c31;
          border-radius: 0.3rem;
          margin-left: 0.3rem;
          transition: 0.5s ease-in-out;
          svg {
            font-size: 1.2rem;
            color: white;
          }
          &:hover {
            background-color: white;
            svg {
              color: #2c2c31;
            }
          }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .footer-container{
        .main-container{
            flex-direction: column;
            text-align: center;
        }
    }
}
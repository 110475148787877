@import "../Utils/colors.scss";

.contact-container {
  background-color: $backgroundColor;
    min-height: 35vh;
    .container {
        margin: 0 10%;
        padding: 5rem 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        .details {
          color: $lightFontColor;
          line-height: 2rem;
          font-size: 1.2rem;
          text-align: justify;
          width: 90%;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
    .contact-container {
      .container {
        grid-template-columns: 1fr;
        .details {
            font-size: 1.2rem;
            line-height: 2rem;
            width: 100%;
        }
        .cards {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .contact-container {
      .container {
        .details {
            font-size: 1.2rem;
            line-height: 2rem;
            width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .contact-container {
      .container {
        gap: 1rem;
        .cards {
          gap: 1rem;
        }
      }
    }
  }
@import "../Utils/colors.scss";

.about-container {
  background-color: $backgroundColor;
    .container {
        margin: 0 10%;
        padding: 5rem 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        .details {
          button {
            margin-top: 3rem;
          }
        }
      .content {
        font-size: 1.2rem;
        color: $lightFontColor;
        line-height: 2rem;
      }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;

        }
    }
}
@media screen and (max-width: 1200px) {
  .about-container {
    .container {
      grid-template-columns: 1fr min-content;

      .cards {
        grid-template-columns: 1fr;
      }
    }
  }
}


@media screen and (min-width: 320px) and (max-width: 480px) {
    .about-container {
      .container {
        grid-template-columns: 1fr;
        .details {
          p {
            font-size: 2.3rem;
            line-height: 2rem;
            width: 100%;
          }
        }
        .cards {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .about-container {
      .container {
        .details {
          p {
            font-size: 1.8rem;
            line-height: 1.7rem;
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .about-container {
      .container {
        gap: 1rem;
        .cards {
          gap: 1rem;
        }
      }
    }
  }
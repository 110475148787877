@import '../Utils/colors.scss';

@font-face {
    font-family: rondo;
    src: url('../Utils/Fonts/rondo.ttf') format('truetype');
}

.brand {
    cursor: pointer;
    span {
        text-transform: uppercase;
        font-size: xx-large;
        font-family: rondo, sans-serif;
        border: 0.1rem solid #f50460;
        color: $blueColor;
        padding: 0.6rem 1rem;
        border-radius: 0.3rem;
        font-weight: bolder;
        letter-spacing: 0.2rem;
    }
}

.footer {
    span {
        color: white;
        border-color: white;
    }
}
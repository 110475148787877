@import "../Utils/colors.scss";

.small {
  width: 15rem;
  margin: 1rem;
  .content {
      width: 15rem;
  }
}

.large {
  width: 25rem;
  .content {
      width: 25rem;
  }
}
.example{
  height: 18rem;
  display: flex;
  flex-direction: column;
  .content {
    .LazyLoad {
      div {
        img {
          //padding: 5rem 0;
        }
      }
    }
  }
}
.realization-container{
    border: none;
    border-radius: 0.4rem;
    background-color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0,0,0,0.15) 0 5px 15px 0;
    padding: 1rem;
    transition: 0.4s ease-in-out;
    &:hover {
      box-shadow: #f32a8f2f 5px 5px, #f32a8f1e 10px 10px, #f32a8f02 15px 15px;
    }

    .content {
        margin-top: 0;
      .LazyLoad {
        img {
          border: 0.1em solid lightgrey;
        }
      }
    }
    .description-container {
        color: $lightFontColor;
        font-weight: 600;
        margin-top: 0.5rem;

        .description {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: rgb(99,15,179);
        }
    }

}

@media screen and (max-width: 768px)  {
    .realization-container {
        margin: 1rem;
        width: 65vw;
        padding: 3rem 3.5rem;
    }
  }

@media screen and (min-width: 320px) and (max-width: 480px) {
    .realization-container {
        margin: 1rem;
        width: 25rem;
        padding: 3rem 3.5rem;
      .content {
        font-size: 1.3rem;
        text-align: center;
      }
      .description-container {
        .description {
          font-size: large;
        }

      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .realization-container {
        margin: 1rem;
        width: 25rem;
        padding: 3rem 3.5rem;
      .content {
        font-size: 1.2rem;
        text-align: center;
      }
      .description-container {
        .description {
          font-size: medium;
        }
      }
    }
  }


@import "../Utils/colors.scss";

.navbar {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    .col {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .collapse-button {
            display:  none;
        }
    }
    nav {
        ul {
            display: flex;
            list-style-type: none;
            justify-content: center;
            align-items: center;
            margin: 0.3rem 3rem 0 0;
            color: $lightFontColor;
            li {
                a {
                    text-decoration: none;
                    font-size: 1.2rem;
                    color: $blueColor;
                    &:hover {
                        color: $blueDarkerColor;
                    }
                }
                text-transform: uppercase;
                margin: 0 1rem;
            }
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .navbar.active {
        nav {
            display: block;
        }
    }
    .navbar {
        flex-direction: column;
        justify-content: flex-start;
        .col {
            justify-content: space-between;
            .collapse-button {
                display: block;
                font-size: 4rem;
            }
        }
        nav {
            display: none;
            .links {
                display: flex;
                ul {
                    align-items: normal;
                    flex-direction: column;
                    padding: 0;
                    li {
                        margin: 2rem 0 0 0;
                        &:first-of-type {
                            margin-top: 4rem;
                        }
                        a {
                            font-size: x-large;
                        }
                    }
                }
            }
        }
    }
}
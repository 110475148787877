@import "../Utils/colors.scss";

.starter-container {
    margin: 0 10% 10% 10%;
    .container {
        display: flex;
        flex-direction: column;
    }
    .content {
        margin-right: 3rem;
        h1 {
            font-size: 3rem;
            color: $headingColor;
        }
        p {
            font-size: 1.4rem;
            color: $lightFontColor;
            line-height: 1.9rem;
        }
      .posibilites {
        font-size: 1.4rem;
        color: $lightFontColor;
        line-height: 1.9rem;
      }
      .onTop {
        font-size: 1.4rem;
        color: $lightFontColor;
        line-height: 1.9rem;
      }
      ul {
        width: 80%;
        list-style: none;

        li {
          color: $lightFontColor;
          margin-bottom: 1rem;
          position: relative;
          &::before{
            content: "";
            position: absolute;
            top: -4px;
            left: -40px;
            width: 20px;
            height: 20px;
            background-image: url("../../Assets/checkCircle.svg");
            background-repeat: no-repeat;
          }
        }
      }
        .button-container {
            display: flex;
            button {
                margin-right: 1rem;
            }
        }
    }
}

  @media screen and (max-width: 768px) {
    html {
      font-size: 10px;
    }
  }
